import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from 'hooks/api';
import { LoadingIndicator } from 'components';
import { UserStudyItem } from './components';
import { fetchAllStudies } from 'api/studies';
import { setUserStudies } from 'store/studies';
import { getUserStudies } from 'store/studies/selectors';
import { getUserDisplayName, getUserShouldSetPin } from 'store/user/selectors';
import './styles.scss';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading } = useApi(fetchAllStudies);

  const userStudies = useSelector(getUserStudies);
  const userDisplayName = useSelector(getUserDisplayName);
  const userShouldSetPin = useSelector(getUserShouldSetPin);

  useEffect(() => {
    if (!isLoading && Array.isArray(data)) {
      dispatch(setUserStudies(data));
    }
  }, [data, isLoading, dispatch]);

  useEffect(() => {
    if (userShouldSetPin) {
      navigate('/create-pin');
    }
  }, [userShouldSetPin, navigate]);

  return (
    <div id="dashboard-page">
      <LoadingIndicator isLoading={isLoading} />

      {!isLoading ? (
        <header>
          <h1>{!!userDisplayName ? `Welcome to Korio, ${userDisplayName}` : null}</h1>
          <p>Please select a study from the list below</p>
        </header>
      ) : null}

      {!!userStudies.length ? (
        <ul className="user-study-list">
          {userStudies.map(userStudy => (
            <UserStudyItem
              key={`user-study-${userStudy.studyId}`}
              {...userStudy}
            />
          ))}
        </ul>
      ) : (
        <div className="no-studies-message">
          <h4>
            There are no studies associated with your user account. If this is
            incorrect, please contact your site monitor.
          </h4>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
