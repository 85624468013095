import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ArrowCircleRight as ArrowCircleRightIcon } from '@mui/icons-material';
import { TYPE } from 'utils/alerts';
import { PASSWORD_REGEX, WEAK_PASSWORD_MESSAGE } from 'utils/account';
import { resetPassword } from 'api/users';

const MODE = {
  CREATE: {
    id: 'create',
    subheading: 'Input your desired password below.',
    placeholder: 'new password',
  },
  CONFIRM: {
    id: 'confirm',
    subheading: 'Please confirm your password by entering it again.',
    placeholder: 'confirm password',
  },
};

const ResetPassword = ({ setIsLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar(); 

  const inputRef = useRef();

  const [mode, setMode] = useState(MODE.CREATE);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const email = useMemo(() => searchParams.get('email'), [searchParams]);
  const pin = useMemo(() => searchParams.get('pin'), [searchParams]);

  const handleValidatePassword = useCallback(() => {
    if (PASSWORD_REGEX.test(password)) {
      setMode(MODE.CONFIRM);
    } else {
      enqueueSnackbar(WEAK_PASSWORD_MESSAGE, { variant: TYPE.ERROR });
    }
  }, [password, enqueueSnackbar]);

  const handleResetPassword = useCallback(() => {
    setIsLoading(true);

    resetPassword({
      pin,
      email,
      password,
      confirmPassword,
    }).then(() => {
      enqueueSnackbar('Your password has been reset.', { variant: TYPE.SUCCESS });

      setSearchParams({ success: true });
      setIsLoading(false);
    }, error => {
      console.error(error);

      enqueueSnackbar('There was a problem resetting your password.', { variant: TYPE.ERROR });

      setPassword('');
      setConfirmPassword('');
      setMode(MODE.CREATE);
      setIsLoading(false);
    });
  }, [pin, email, password, confirmPassword, enqueueSnackbar, setSearchParams, setIsLoading]);

  const [value, setValue, onSubmit] = useMemo(() => {
    switch (mode.id) {
    case MODE.CONFIRM.id:
      return [confirmPassword, setConfirmPassword, handleResetPassword];
    case MODE.CREATE.id:
    default:
      return [password, setPassword, handleValidatePassword];
    }
  }, [mode, confirmPassword, password, handleResetPassword, handleValidatePassword]);

  const onKeyDown = useCallback(({ keyCode }) => {
    if (keyCode === 13) {
      onSubmit();
    }
  }, [onSubmit]);

  const onChange = useCallback(({ target }) => {
    setValue(target.value);
  }, [setValue]);

  useEffect(() => {
    if ([MODE.CREATE.id, MODE.CONFIRM.id].includes(mode.id)) {
      inputRef.current.focus();
    }
  }, [mode]);

  return (
    <section>
      <p>{mode.subheading}</p>
      <div className="input-wrapper">
        <input
          ref={inputRef}
          type="password"
          placeholder={mode.placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <div 
          className="btn-next"
          onClick={onSubmit}>
          <ArrowCircleRightIcon /> 
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
