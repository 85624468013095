import React, { 
  createRef, 
  useState, 
  useMemo, 
  useEffect, 
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { usePostApi } from 'hooks/api';
import { LoadingIndicator } from 'components';
import { TYPE } from 'utils/alerts';
import { createUserPin } from 'api/users';
import { setUnblindingPin } from 'store/user';
import './styles.scss';

const MODE = { 
  CREATE: {
    id: 'create',
    heading: 'Create Your PIN',
    subheading: 'Your 6-character PIN number will be needed to confirm your intent to perform critical tasks such as unblinding patient details.',
  },
  CONFIRM: {
    id: 'confirm',
    heading: 'Confirm Your PIN',
    subheading: 'Please confirm your 6-character PIN number by re-entering it below.',
  },
};

const PIN_LENGTH = 6;
const INIT_VALUES = [...Array(PIN_LENGTH)].map(() => '');

const CreatePin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const { post, isLoading } = usePostApi(createUserPin);

  const inputRefs = useMemo(() => {
    return INIT_VALUES.map(() => createRef(null));
  }, []);

  const [mode, setMode] = useState(MODE.CREATE);

  const [pinValues, setPinValues] = useState(INIT_VALUES);
  const [confirmPinValues, setConfirmPinValues] = useState(INIT_VALUES);

  const [values, setValues] = useMemo(() => {
    switch (mode.id) {
    case MODE.CONFIRM.id:
      return [confirmPinValues, setConfirmPinValues];
    case MODE.CREATE.id:
    default:
      return [pinValues, setPinValues];
    }
  }, [mode, confirmPinValues, pinValues]);

  const pin = useMemo(() => pinValues.join(''), [pinValues]);
  const confirmPin = useMemo(() => confirmPinValues.join(''), [confirmPinValues]);

  const setPinValueAtIndex = useCallback((index, value) => {
    setValues(prev => {
      const next = [...prev];

      next[index] = value;

      return next;
    });
  }, [setValues]);

  const onInputChange = useCallback((idx, { target }) => {
    setPinValueAtIndex(idx, target.value);

    if (idx + 1 < PIN_LENGTH && target.value !== '') {
      inputRefs[idx + 1].current.focus();
    }
  }, [setPinValueAtIndex, inputRefs]);

  const onInputKeyDown = useCallback((idx, { keyCode, target }) => {
    if (keyCode === 8) {
      if (idx > 0 && target.value === '') {
        inputRefs[idx - 1].current.focus();
      } else if (mode.id === MODE.CONFIRM.id && idx < 1 && target.value === '') {
        setMode(MODE.CREATE);

        inputRefs[PIN_LENGTH - 1].current.focus();
      }
    }
  }, [mode, inputRefs]);

  const onSubmit = useCallback(() => {
    post({ pin, confirmPin }).then(resp => {
      enqueueSnackbar('Your PIN has been set.', { variant: TYPE.SUCCESS });

      dispatch(setUnblindingPin(resp));
      
      navigate('/');
    }).catch(error => {
      console.error(error);

      enqueueSnackbar('There was a problem setting your PIN.', { variant: TYPE.ERROR });

      setPinValues(INIT_VALUES);
      setConfirmPinValues(INIT_VALUES);
      setMode(MODE.CREATE);

      inputRefs[0].current.focus();
    });
  }, [post, pin, confirmPin, inputRefs, enqueueSnackbar, navigate, dispatch]);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, [inputRefs]);

  useEffect(() => {
    if (pin.length === PIN_LENGTH) {
      setMode(MODE.CONFIRM);

      inputRefs[0].current.focus();
    }
  }, [pin, inputRefs]);

  useEffect(() => {
    if (confirmPin.length === PIN_LENGTH) {
      onSubmit();
    }
  }, [confirmPin, onSubmit]);

  return (
    <div id="create-pin-page">
      <LoadingIndicator isLoading={isLoading} />
      <header>
        <h1>{mode.heading}</h1>
      </header>
      <section>
        <p>{mode.subheading}</p>
        <div className="inputs-container">
          {values.map((value, idx) => {
            return (
              <input
                key={`pin-input-${idx}`}
                ref={inputRefs[idx]}
                value={value}
                onChange={onInputChange.bind(this, idx)}
                onKeyDown={onInputKeyDown.bind(this, idx)}
                maxLength="1"
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default CreatePin;
